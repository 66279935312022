import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';

export default class AcademyService{
    key =localStorage.getItem('apiKey')
    async getImages(length,start){
        const response = await fetch(BASE_URL+'academy/all_courses?length='+length+'&start='+start, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        return await response.json();
    }


    async getFolders(){
        const response = await fetch(BASE_URL+'tutorial/get_folders', {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            },
        
        })
        return await response.json();
    }

    async getCourdeDetails(id){
        const response = await fetch(BASE_URL+`tutorial/get_uploads?id=${id}`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            },
        
        })
        return await response.json();
    }

    async getvideoData(id){
        const response = await fetch(BASE_URL+`tutorial/tutorial_single_view?video_id=${id}`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            },
           
        
        })
        return await response.json();
    }

    async postProgress(pause_time){
        const response = await fetch(BASE_URL+`tutorial/video-status`, {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            },
           body:JSON.stringify(pause_time)
        
        })
        return await response.json();
    }
        async postVideoStatus(pause_time){
        const response = await fetch(BASE_URL+`tutorial/video-status`, {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            },
           body:JSON.stringify(pause_time)
        
        })
        return await response.json();
    }
}