import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import AcademyService from "../../../service/Academy/Academy";
import Spinner from "../../shared/Spinner";
import { Card, Row, Col, Button } from "react-bootstrap";
import style from "./academic.module.scss";
import ReactPlayer from "react-player";
// import noVideo from "../../../assets/images/novideo.png";
import { useTranslation } from "react-i18next";
import Vimeo from "@u-wave/react-vimeo";
// import VimeoPlayer from "@u-wave/react-vimeo";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import nodata from "../../../assets/images/nophoto/no-datas-found.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

import './Folder.css'

function SingleViewVideo() {
  const history = useHistory();

  const [currentTime, setCurrentTime] = useState(0);

  const [reachedEnd, setReachedEnd] = useState(true);

  const handleTimeUpdate = (data) => {
    // console.log('Current time:', data.seconds);
    setCurrentTime(data.seconds);
  };

  const handlePlay = () => {
    // Handle play event
    console.log("played");
  };

  const handleEnded = () => {
    let formdata = {
      video_id: id,
      pause_time: currentTime,
      end_time: true,
    };
    service.postVideoStatus(formdata).then((res) => {
      if (res.status) {
        console.log(res);
      }
    });

    setReachedEnd(true);
  };

  const handlePause = () => {
    let formdata = {
      video_id: id,
      pause_time: currentTime,
      end_time: false,
    };
    service.postProgress(formdata).then((res) => {
      if (res.status) {
        console.log(res);
      }
    });
  };

  const handleSeeked = () => {
    // Handle seeked event and update the current time
  console.log("seek - time:", currentTime);
  };

  const service = new AcademyService();
  const { id } = useParams();
  const [Loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  let correctAnswers = [];
  const [answers, setAnswers] = useState([]);
  const [state, setState] = useState({
    setIndex: 0,
    data: [],
    pause_time: 0,
    loader: false,
    count: "",
    video: "",
    pdf: "",
    description: "",
    title: "",
    score: "",
    tempAarray: [],
    error: "",
    index: 0,
    quizStatus: false,
  });
  useEffect(() => {
    postCourseId();
  }, []);
  const postCourseId = () => {
    service.getvideoData(id).then((res) => {
      if (res.status) {
        setLoading(false);
        setState((prev) => ({
          ...prev,
          // data: res.data.questionArray[0] ? [res.data.questionArray[0]] : [],
          video: res.data.video,
          pause_time: res.data.pause_time,
          // tempAarray: res.data.questionArray,
          count: res.data.count,
          description: res.data.description,
          score: res.data.score,
          title: res.data.title,
          quizStatus: res.data.allQuestionAnswered,
          pdf: res.data,
        }));
      } else {
        setLoading(true);
      }
    });
  };

  return (
    <>
      {Loading ? (
        <Spinner />
      ) : (
        <div
         className={`${style.viewtutorial} vimeo`}
         >
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  {state.video && (
                    <Vimeo
                      // ref={playerRef}
                      video={state.video}
                      controls={true}
                      volume={0}
                      textTrack="eng"
                      start={state.pause_time}
                      onTimeUpdate={handleTimeUpdate}
                      // autoplay={true}
                      onPause={handlePause}
                      onPlay={handlePlay}
                      onSeeked={handleSeeked}
                      onEnd={handleEnded}
                      responsive={true}
                      // width='560'
                    />
                  )}
                </Col>
                {/* <Col >
                  <h3
                    style={{ fontWeight: "bolder", color: "rgb(41, 152, 187)" }}
                  >
                    {state.title}
                  </h3>
                  <p>{state.description}</p>
                  <br />
       
                </Col> */}
              </Row>
            </Card.Body>
          </Card>

          {state.pdf.pdfStatus == 1 &&
            state.pdf.pdf.map((data) => {
              return (
                <div
                  style={{
                    background: "#fff",
                    padding: "10px",
                    marginBottom: "10px",
                    fontWeight: "bolder",
                  }}
                >
                  <a href={data} download>
                    {" "}
                    <FontAwesomeIcon
                      icon={faFilePdf}
                      style={{ color: "#d60004", fontSize: "24px" }}
                    />{" "}
                    Download PDF
                  </a>
                </div>
              );
            })}

          {/* <>
          {
            state.data.length > 0 ? (
              <div>
                {state.data.map((obj, indexs) => {
                  console.log("alldata", obj)
                  return (
                    <Row>
                      <Col sm={6} md={12} lg={12}>
                        <Card>
                          <Card.Body
                            className={`${style.panelBody} ${style.container} ${style.ttr_qst_box}`}
                          >
                            <h5>{obj?.question} </h5>
                            <button
                              className={style.showAnswerBtn}
                              style={{
                                float: "right",
                                color: "#242424",
                              }}
                              onClick={() => showAnser(obj.questionId)}
                            >
                              {obj.showAnser ? "Hide Answer" : "Show Answer"}
                            </button>
                            <div className={style.QuestionsOptionBox}>
                              {obj.options.map((radio, index) => {
                                return (
                                  <>
                                    <div>
                                      {obj.showAnser ? (
                                        <>
                                          <input
                                            type={radio.type}
                                            id={obj.id}
                                            name={radio.name}
                                            value={radio.value}
                                            readOnly
                                            checked={
                                              obj.correctAnswerid == radio.value
                                            }
                                          />
                                          <label for={radio.value + indexs}>
                                            {radio.title}
                                          </label>
                                        </>
                                      ) : (
                                        <>
                                          <input
                                            type={radio.type}
                                            id={radio.id}
                                            name={radio.name}
                                            value={radio.value}
                                            checked={obj.answerType == radio.value}
                                            onChange={() =>
                                              onclcikAnswer(
                                                radio.value,
                                                obj.questionId
                                              )
                                            }
                                          />
                                          <label for={radio.id}>{radio.title}</label>
                                        </>
                                      )}
                                    </div>
                                  </>
                                );
                              })}

                              {state?.error && (
                                <div
                                  style={{
                                    // position: "absolute",
                                    color: "red",
                                    fontSize: "12px",
                                    bottom: "4px",
                                  }}
                                >
                                  {state?.error}
                                </div>
                              )}
                            </div>
                          </Card.Body>
                          <Card.Footer>
                            {page != 0 && (
                              <Button
                                style={{ width: "10%", marginRight: "2px" }}
                                onClick={() => OnPrevclik(obj, indexs)}
                              >
                                Previous
                              </Button>
                            )}

                            {!obj.showAnser && page != state.count - 1 ? (

                              <Button
                                style={{ width: "10%" }}
                                onClick={() => OnNextclik(obj, obj.index)}
                              >
                                Next
                              </Button>
                            ) : (
                              <Button
                                className="btn btn-primary"
                                style={{
                                  width: "10%",
                                  backgroundColor: "green",
                                  color: "white",
                                }}
                                onClick={submitAnswer}
                              >
                                Submit
                              </Button>
                            )}
                          </Card.Footer>
                        </Card>
                      </Col>
                    </Row>
                  );
                })}
              </div>

            )
              :
              <div className={`${style.NodataImage}`} style={{ textAlign: "center" }}>
                <center>
                  <img src={nodata} alt="nodata" style={{ width: "50%" }} />
                </center>
              </div>
          }

          </> */}
        </div>
      )}
    </>
  );
}

export default SingleViewVideo;
