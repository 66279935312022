import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import style from "./registration.module.scss";
import { Paneltilte, Buttons } from "./";
import FormsControl from "./FormsControl";

function SponsorPackage(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const [data, setData] = useState([]);
  //change handler
  const changehandler = (e) => {
    props.ChangeHandler(e, "sponsor");
  };
  const Currency = useSelector((state) => state.curr);
  useEffect(() => {
   let  position; 
    let newData =
      props?.data &&
      props.data.map((object) => {
        if (object.code === "position") {
          position = object.value
          let newOption = object.options.map((option) => {
            return {
              ...option,
              code: "profile." + option.code,
            };
          });
          return {
            ...object,
            options: newOption,
          };
        } else if (object.code === "product") {

          let newOption = object.options.map((option) => {
            let amount = (option.productValue * Currency.value).toFixed(
              Currency.precision
            );
            return {
              ...option,
              code:
                option.code,
            };
          });
          return {
            ...object,
            options: newOption,
          };
        } else {
          return object;
        }
      });
    setData(newData);
  }, [props.data, Currency]);
  return (
    <div
      className={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_SponsorPackage}`
          : `${style.SponsorPackage}`
      }`}
    >
      <Paneltilte tilte={props.title.code} />
      <Form
        onSubmit={(e) => {
          props.submit(e,"", "sponsor");
        }}
      >
        {data.map((field, index) => {
          return (
            <FormsControl
              key={index}
              data={field}
              change={changehandler}
              blurhandler={props.blurhandler}
            />
          );
        })}
        <Buttons prev={false} />
      </Form>
    </div>
  );
}

export default SponsorPackage;
