import { React, useState, useEffect } from "react";
import style from "../../components/AcademicTutorials/academic.module.scss";
import {
  Card,
  Container,
  CardGroup,
  Button,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import Spinner from "../../shared/Spinner";
import nodata from "../../../assets/images/nophoto/no-datas-found.png";
import { positions } from "@mui/system";
// import ReactPlayer from "react-player";
import Vimeo from "@u-wave/react-vimeo";
import AcademyService from "../../../service/Academy/Academy";
import { Link } from "react-router-dom";

import './sizes.css'

import ProgressBar from 'react-bootstrap/ProgressBar';

import { Skeleton } from '@mui/material';

function CourseDetails(props) {
  const { t } = useTranslation();
  const { currentTheme } = useSelector((state) => state.Theme);
  const currency = useSelector((state) => state.curr);
  const history = useHistory();
  const { id } = useParams();
  const [currentTime, setCurrentTime] = useState(0);


  const service = new AcademyService();


  const [state, setState] = useState({
    data: [],
    loader: false,
    count: "",
    video: [],
  });
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      loader: true,
    }));
    service.getCourdeDetails(id).then((res) => {
      console.log(res);
      if (res.status) {
        setState({
          ...state,
          data: res.data,
          //   data: res.data.questionArray,
          //   video: res.data.video,
          //   count: res.data.count,
          //   loader: false,
        });
      } else {
      }
    });
  }, []);
  const onProgress = (event) => {
    // console.log(event);
  };

  console.log('fooj', state?.data);

  const handleTimeUpdate = (data) => {
    // console.log('Current time:', data.seconds);
    setCurrentTime(data.seconds);
  };

  const handlePause = () => {
    let formdata = {
      video_id: id,
      pause_time: currentTime,
      end_time: false,
    };
    service.postProgress(formdata).then((res) => {
      if (res.status) {
        console.log(res);
      }
    });
  };

  const handleEnded = () => {
    let formdata = {
      video_id: id,
      pause_time: currentTime,
      end_time: true,
    };
    service.postVideoStatus(formdata).then((res) => {
      if (res.status) {
        console.log(res);
      }
    });

  };


  console.log("videodataa", state.data)
  return state.loader ? (
    // <Spinner />
    <div style={{ display: 'flex' }}>
      <div style={{ marginLeft: '20px', marginTop: '10px' }}>
        <Skeleton variant="rectangular" animation="wave" width={250} height={150} />
        <Skeleton animation="wave" width="100%" />
        <Skeleton animation="wave" width="80%" />
      </div>
      <div style={{ marginLeft: '20px', marginTop: '10px' }}>
        <Skeleton variant="rectangular" animation="wave" width={250} height={150} />
        <Skeleton animation="wave" width="100%" />
        <Skeleton animation="wave" width="80%" />
      </div>
      <div style={{ marginLeft: '20px', marginTop: '10px' }}>
        <Skeleton variant="rectangular" animation="wave" width={250} height={150} />
        <Skeleton animation="wave" width="100%" />
        <Skeleton animation="wave" width="80%" />
      </div>
      <div style={{ marginLeft: '20px', marginTop: '10px' }}>
        <Skeleton variant="rectangular" animation="wave" width={250} height={150} />
        <Skeleton animation="wave" width="100%" />
        <Skeleton animation="wave" width="80%" />
      </div>

    </div>

  ) : (
    <div
      className={`${style.downloadConatiner} vimeo`}
    >
      <div
        className={style.userDowmloadSection} style={{ minHight: "80vh" }}
      >
        <Row>
          {state && state.data.length > 0 ? (
            state.data.map((items, index) => (
              <Col sm={12} md={6} lg={4} key={index}>
                <Card
                  style={{
                    // width: "100%",
                    padding: "20px",
                    paddingBottom: "5px",
                    borderRadius: '10px',
                    background: 'none',
                    boxShadow: 'none'

                  }}
                >


                  <Card.Body style={{ background: '#fff', padding: '15px 10px 10px 10px', boxShadow: '0px 0px 20px #ccc', borderRadius: '6px' }}>
                    <Link to={`/view-video/${items.video_id}`}>
                      <center className="vimeo">


                        <Vimeo
                          video={items?.link}
                          // autoplay
                          controls={false}
                          // loop={true}
                          volume={0}
                          // progressInterval={1000}
                          textTrack="eng"
                          onProgress={onProgress}
                          
                          onPause={handlePause}

                          onEnd={handleEnded}

                          onTimeUpdate={handleTimeUpdate}

                          // style={{height:'100px'}}


                          responsive={true}


                          width="260"
                        />

                      </center>

                      <ProgressBar style={{ height: '3px', borderRadius: '35px', fontWeight: '900' }} variant='danger'
                        now={items?.progress}
                      // now='60'
                      //  label={`50%` } 
                      />

                      <div
                        style={{ display: 'flex', justifyContent: 'space-between', color: '#000' }}
                      >

                        <div
                          style={{
                            // textAlign: "center",
                            color: "#2998bb",
                            fontsize: "12px",
                            fontWeight: "bolder",
                            // fontSize: "18px",
                            marginBottom: "3px",
                            marginTop: '10px',
                          }}
                        >
                          {items.name}
                        </div>


                        {/* 
                  <div>
                    {`${items.progress}%`}
                  </div> */}

                      </div>

                    </Link>
                  </Card.Body>

                </Card>
              </Col>
            ))
          ) : (
            <>
              <div className={`${style.NodataImage}`}>
                <center>
                  <img src={nodata} alt="nodata" style={{ width: "50%" }} />
                </center>
              </div>
            </>
          )}
        </Row>
      </div>
      {/* {state.data.length > 0 && (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    )} */}
    </div>
  );
}

export default CourseDetails;
