import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomButton } from "../common";
import { useTranslation } from "react-i18next";
function PaypalPayment(props) {
  console.log("🚀 ~ file: PaypalPayment.jsx:5 ~ PaypalPayment ~ props:", props)

  const [paid, setPaid] = useState(false);
  const [error, setError] = useState(null);
  const paypalRef = useRef();
  const { t } = useTranslation();
  //
  const passPaymentDetails = useCallback(
    (id) => {
      console.log("i am inside payment details", id);
      props.getPaypalData(id);
    },
    [props]
  );

  //initialise the paypal config
  useEffect(() => {
    // props.planId && (
    window.paypal
      .Buttons({
        createSubscription: (data, actions) => {

          // return actions.subscription.create({
          //   plan_id: props.planId,
          // });

          // if (props.isUpgrade) {
          //   console.log('called');
          //   return actions.subscription.revise('I-CSC5DUYFSHCT', {
          //     plan_id: props.planId,
          //   });
          // } else {
            // Create a new subscription
            return actions.subscription.create({
              plan_id: props.planId,
              custom_id: props.customId
            });
          // }
        },
        onApprove: async (data, actions) => {
          setPaid(true);
          passPaymentDetails({
            subscriptionID: data.subscriptionID,
            data: data,
          });
        },
        onError: async (error) => {
          console.log(error)
        }

      })
      .render(paypalRef.current)
    // ) 

  }, [props.amount, props.planId]);

  const clickHandler = (e) => {
    props.checkout && props.checkout(false);

    // if (
    //   window.location.pathname.includes("/register") ||
    //   window.location.pathname.includes("/replica_register")
    // ) {
    props.submit(e, "", "payment");
    // } else {
    //   props.submit(e);
    // }
  };

  // If the payment has been made
  if (paid) {
    return (
      <CustomButton variant="primary" type="submit" onClick={clickHandler}>
        {t("Button.finish")}
      </CustomButton>
    );
  }

  // If any error occurs
  if (error) {
    return <div>Error Occurred in processing payment.! Please try again.</div>;
  }
  return (
    <div className="p-2">
      <div ref={paypalRef} />
    </div>
  );
}

export default PaypalPayment;
