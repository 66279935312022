import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import AcademyService from "../../../service/Academy/Academy";
import Spinner from "../../shared/Spinner";
import { Card, Row, Col, Form } from "react-bootstrap";
import style from "./academic.module.scss";
import nodata from "../../../assets/images/nophoto/no-datas-found.png";
import folderIcon from "../../../assets/images/folderIcon.png";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import './Folder.css'
// import noVideo from "../../../assets/images/novideo.png";
function ViewTutorialContent() {
  const service = new AcademyService();
  const { id } = useParams();
  const [Loading, setLoading] = useState(true);
  const [state, setstate] = useState({
    data: [],
  });
  useEffect(() => {
    getFolders();
  }, []);
  const getFolders = () => {
    service.getFolders().then((res) => {
      console.log(res);
      if (res.status) {
        setLoading(false);
        setstate((prev) => ({
          ...prev,
          data: res.data,
        }));
      } else {
        setLoading(true);
      }
    });
  };



  const download = (e) => {
    var fn = e.match(/([^/])+/g);
    fn = fn[fn.length - 1];
    fetch(e).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fn;
        a.click();
      });
    });
  };
  return (
    <>
      {Loading ? (
        <Spinner />
      ) : (
        <div className={`${style.downloadConatiner} vimeo`}>
     
          {state.data.length > 0 ? (
            <>
              <Row>
                {state.data.map((obj, index) => {
                  return (
                    <Col sm={12} md={2} lg={2} key={index}>
                      <Link
                        className={style.tutorials}
                        to={`/view_courses/${obj.id}`}
                      >
                        <Card className="folderHover">
                          <Card.Body
                            className={`${style.panelBody} ${style.container}`}
                          >
                            <Form>
                              <div style={{marginLeft:'auto', marginRight:'auto'}}>
                                <div style={{width:'80px', marginLeft:'auto', marginRight:'auto', position:'relative'}} md={4}>
                                {obj.updateStatus == 1 ? 
                              <div style={{width:'9px', height:'9px', background:'#e22222', position:'absolute', borderRadius:'30px', border:'2px #F5F7FD solid', right:1, top:16}}></div>

                              :
                              null}
                                  {/* <div > */}
                                  <img
                                  
                                    // src={obj.image}
                                    src={folderIcon}
                                    alt="folder"
                                    width="100%"
                                    height="100%"
                                  />
                                  {/* </div> */}
                                  </div>
                                </div>
                                <div style={{textAlign:'center'}}>
                                  <h6 style={{fontSize:'16px', color:"rgb(32, 32, 32)"}}>{obj.name}</h6>
                                </div>
                            </Form>
                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                  );
                })}
              </Row>
            </>
          ) : (
            <div
              className={`${style.NodataImage}`}
              style={{ textAlign: "center" }}
            >
              <center>
                <img src={nodata} alt="nodata" style={{ width: "50%" }} />
              </center>
            </div>
          )}
        </div>
        
      )}
    </>
  );
}

export default ViewTutorialContent;
