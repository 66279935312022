import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import PageTitle from "../../components/common/pageTitle";
import { UpgradeContent } from "../../components/package";
import ProfileService from "../../../service/profile";
import Payments from "../../../service/common/PaymentApi";
import { withRouter } from "react-router-dom";
import Spinner from "../../shared/Spinner";
import companyName from "../../../store/mobxStore/companyName";
import { useHistory } from "react-router-dom";

// import

function Upgrade(props) {
  let _isMounted = false;

  const [state, setState] = useState({

    current_package_details: {
      product_id: "",
      package_id: "",
      product_name: "",
      price: "",
      pv: "",
    },
    upgrade_list: [],
    payment_methods: [],
    selectedPackage: null,
    selectedPayment: null,

    alert: {
      show: false,
      message: "",
      varient: "",
    },

    ewalletData: {
      username: "",
      password: "",
    },

    epinData: {
      pinArray: [],
    },
    paypal: {
      orderId: "",
      payeerId: "",
    },
    loading: true,
  });
  const [loading, setLoading] = useState(false)

  const [clientSecret, setClientSecret] = useState("");
  const history = useHistory();
  const service = new ProfileService();
  const Service = new Payments();

  useEffect(() => {
    _isMounted = true;
    Service.createPayment(props.product, "Paypal").then((res) => {
      if (res.status) {
        setClientSecret(res.data.public_key);
      }
    });
    loadPaymentData();
    loadDetails();
    return () => {
      // Anything in here is fired on component unmount.
      _isMounted = false;
    };
  }, [state.current_package_details.product_id]);

  //load details

  const loadDetails = () => {

    service.upgradeDetails().then((res) => {
      if (res.status) {
        if (_isMounted) {
          setState((prevState) => ({
            ...prevState,
            current_package_details: res.data.current_package_details,
            upgrade_list: res.data.upgrade_list,
            loading: false,
          }));

        }
        if (res.data.upgrade_list.length === 0) {
          props.history.push({
            pathname: "/dashboard",
            state: {
              error: true,
              message: "permissionDenied",
            },
          });
        }
      } else {
        if (res.error.code === 1002 || res.error.code === 1001) {
          props.history.push("/logout");
        }
      }
    });
  };

  // Load Payment data
  const loadPaymentData = () => {
    service.getPaymentData("upgradation").then((res) => {
      if (res.status) {
        if (_isMounted) {
          setState((prevState) => ({
            ...prevState,
            payment_methods: res.data,
            selectedPayment: res.data[0]

          }));
        }
      } else {
        if (res.error.code === 1002 || res.error.code === 1001) {
          props.history.push("/logout");
        }
      }
    });
  };

  // select package from
  const selectPackage = (event) => {
    let selectedPackage = {};
    selectedPackage = state.upgrade_list.find((item) => {
      return item.product_id == event.target.value;
    });
    setState((prevState) => ({
      ...prevState,
      selectedPackage,
    }));
  };

  // Payment method change event
  const changeTabHandler = (tab) => {
    setState((prevState) => ({
      ...prevState,
      selectedPayment: state.payment_methods[tab],
    }));
  };

  //get the ewallet data
  const getEwalletData = (username, password) => {
    setState((prevState) => ({
      ...prevState,
      ewalletData: {
        username: username,
        password: password,
      },
    }));
  };

  const getEpinData = (pinArray) => {
    if (pinArray.length > 0) {
      setState((prevState) => ({
        ...prevState,
        epinData: {
          pinArray,
        },
      }));
    }
  };

  const submitPackageUpgrade = (event, token) => {
    if (event.hasOwnProperty('defaultPrevented')) {
      event.preventDefault()
    }
    let formData = {
      product_id: state.selectedPackage.product_id,
      payment_method: state.selectedPayment.code,
    };
    //payment details
    if (state.selectedPayment.code === "ewallet") {
      formData.user_name_ewallet = state.ewalletData.username;
      formData.tran_pass_ewallet = state.ewalletData.password;
    } else if (state.selectedPayment.code == "epin") {
      formData.pin_array = state.epinData.pinArray.length;
      state.epinData.pinArray.forEach((element, index) => {
        formData["epin" + (index + 1)] = element.pin;
      });
    }
    else if (state.selectedPayment.code === "paypal") {
      formData.paypal_token = state.paypal.orderId;
      formData.PayerID = state.paypal.payeerId;
      formData.subscriptionID = state.paypal.subscriptionID;
      formData.data = state.paypal.data;

      formData.currency = "USD";
    }

    else if (state.selectedPayment.code === "stripe") {
      formData.stripe_token = event;
      formData.currency = "USD";
    }
    setLoading(true);
    service.packageUpgradeSubmit(formData).then((res) => {
      if (res.status) {
        setLoading(false);
        let message = res.data.message ? res.data.message : 'package_upgrade_success';
        setState((prevState) => ({
          ...prevState,
          current_package_details: {
            product_id: "",
            package_id: "",
            product_name: "",
            price: "",
            pv: "",
          },
          upgrade_list: [],
          payment_methods: [],
          selectedPackage: null,
          selectedPayment: null,
          alert: {
            show: true,
            message: `validation.${message}`, //Change here man !! package_upgrade_success
            varient: "success",
          },
          selectedTab: 1,

          ewalletData: {
            username: "",
            password: "",
          },

          epinData: {
            pinArray: [],
          },
        }));
        window.scrollTo(0, { behaviour: "smooth" });
        history.push('/dashboard')

        loadDetails();
      } else {
        setLoading(false)
        if (res.error.code === 1002) {
          props.history.push("/logout");
        } else if (res.error.code === 1030) {

          setState((prevState) => ({
            ...prevState,
            alert: {
              show: true,
              message: res.error.description,
              varient: "danger",
            },
            selectedTab: 1,
          }));
          window.scrollTo(0, { behaviour: "smooth" });
        } else if (res.error.code === 1049) {
          setState((prevState) => ({
            ...prevState,
            alert: {
              show: true,
              message: "Invalid payment type",
              varient: "danger",
            },
            selectedTab: 1,
          }));
          window.scrollTo(0, { behaviour: "smooth" });
        } else if (res.error.code === 1014) {
          setState((prevState) => ({
            ...prevState,
            alert: {
              show: true,
              message: "Insufficient E-wallet Balance",
              varient: "danger",
            },
          }));
          window.scrollTo(0, { behaviour: "smooth" });
        } else if (res.error.code === 1004) {
          // Object.keys(res.error.fields).map((key, index)=> {})
        }
      }
    });
  };

  //   componentDidMount() {
  //     _isMounted = true;
  //     loadPaymentData();
  //     loadDetails();
  //   }




  //   componentWillUnmount() {
  //     _isMounted = false;
  //   }



  // const paypalData = (data) => {

  //   if (data) {
  //     setState((prevState) => ({
  //         ...prevState,
  //       paypal: {
  //         orderId: data.orderID,
  //         payeerId: data.payerID,
  //       },
  //     }));
  //   }
  // };

  const paypalData = (data) => {
    if (data) {
      setState((prevState) => ({
        ...prevState,
        paypal: {
          subscriptionID: data?.subscriptionID,
          data: data.data,
          orderId: data?.orderID,
          payeerId: data?.payerID,
        },

      }));
    }
  };

  const { t } = props;
  if (state.loading) {

    return <Spinner />;
  }
  return (

    <div className={`h-100`}>
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.packageUpgrade")}
        </title>
        <script
          src={`https://www.paypal.com/sdk/js?client-id=${clientSecret}&vault=true&intent=subscription`}
        ></script>

      </Helmet>
      <PageTitle title="packageUpgrade" buttonOn={false} />

      <UpgradeContent
        data={state.current_package_details}
        upgrade_list={state.upgrade_list}
        onSumbit={submitPackageUpgrade}
        paymentData={state.payment_methods}
        selectPackage={selectPackage}
        selected_package={state.selectedPackage}
        tabChange={changeTabHandler}
        alert={state.alert}
        ewallet={getEwalletData}
        epin={getEpinData}
        getPaypalData={paypalData}
        loading={loading}
        isUpgrade={true}
        customId={"upgrade"}
      />
    </div>
  );

}

export default withTranslation()(withRouter(Upgrade));
